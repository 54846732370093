<!--
 * @Author: your name
 * @Date: 2020-10-15 10:17:24
 * @LastEditTime: 2021-06-03 09:35:37
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/views/Bind/selectBindAccount.vue
-->
<template>
  <div class="selectBindAccount-page">
    <div class="acount-title">
      <p>选择绑定用水账户</p>
      <span v-if="code === '0004' || code === '0005' || code === '0006'">
        客户"{{ userName }}"有{{ num }}个用水状态正常的账户,请选择要绑定的账户
      </span>
      <span v-else-if="code === '0007'">
        客户"{{ userName }}"有{{ num }}个已报停的用水账户,请选择要绑定的账户
      </span>
      <span v-else> 客户"{{ userName }}"有{{ num }}个用水账户,请选择要绑定的账户 </span>
    </div>
    <div class="account-content" v-if="dataInfoFlag">
      <ul v-if="isMultiple">
        <li v-for="(item, index) in dataInfo.infos" :key="index">
          <van-cell-group>
            <van-field label="户号" v-model="item.userCode" readonly label-width="50px">
              <template #button>
                <van-checkbox v-model="item.isChoose" :disabled="item.isDisabled" />
              </template>
            </van-field>

            <van-field label="地址" v-model="item.address" readonly label-width="50px" />
            <!-- 标签 -->
            <van-cell>
              <label-tag
                :value="item.signName"
                :currentIndex="index"
                @getTagValue="getInputValueInfo"
              />
            </van-cell>
          </van-cell-group>
        </li>
      </ul>
      <!-- 单选 -->
      <ul v-else>
        <li v-for="(item, index) in dataInfo.infos" :key="index">
          <van-cell-group>
            <van-radio-group v-model="result">
              <van-field label="户号" v-model="item.userCode" readonly label-width="50px">
                <template #button>
                  <van-radio :name="item.userCode" />
                </template>
              </van-field>
            </van-radio-group>

            <van-field label="地址" v-model="item.address" readonly label-width="50px" />
            <!-- 标签 -->
            <van-cell>
              <label-tag
                :value="item.signName"
                :currentIndex="index"
                @getTagValue="getInputValueInfo"
              />
            </van-cell>
          </van-cell-group>
        </li>
      </ul>
      <div class="account-submit">
        <van-button type="info" block @click="submitContent">立即绑定</van-button>
      </div>
    </div>
    <no-data v-else />
  </div>
</template>

<script>
import { bindUserByIdNum, bindUserById } from '../../api/home';

import LabelTag from '@/components/labelTag';
import { Dialog, Toast } from 'vant';
import store from '@/store';
import { mapState } from 'vuex';
import {debounce} from "lodash-es";

export default {
  name: 'selectBindAccount',
  components: {
    LabelTag
  },
  computed: {
    ...mapState('user', {
      homeInfo: (state) => state.homeInfo,
      pathUrl: (state) => state.pathUrl
    })
  },
  data() {
    return {
      dataInfo: [],
      userName: '',
      num: '',
      isMultiple: true, // 默认多选
      result: '',
      code: '',
      dataInfoFlag: true
    };
  },
  mounted() {
    this.bindUserByIdNum();
    // 用户绑定的页面进来时 多选 如果时业务办理 进来时 单选
    if (this.pathUrl && this.pathUrl.url && this.pathUrl.url.length > 0) {
      this.code = this.pathUrl.code;
      this.isMultiple = false;
    } else {
      this.isMultiple = true;
    }
  },
  methods: {
    async bindUserByIdNum() {
      let arg = {
        ...this.$route.query.dataInfo
      };
      const { status, resultData } = await bindUserByIdNum(arg);
      if (status === 'complete') {
        this.userName = resultData.bindInfo.userName;
        if (this.isMultiple) {
          resultData &&
            resultData.infos.map((item) => {
              if (item.isChoose) {
                item.isDisabled = true;
              } else {
                item.isDisabled = false;
              }
            });
        }
        // 过滤没有绑定的数据
        let unBindData = [];
        resultData &&
          resultData.infos.map((item) => {
            if (!item.isChoose) {
              unBindData.push(item);
            }
          });
        resultData.infos = unBindData;
        this.num = resultData.infos.length;
        if (resultData.infos.length <= 0) {
          this.dataInfoFlag = false;
          Dialog.alert({
            message: '非常抱歉，未查询到该客户的任何未绑定的用水账户，如有疑问请联系客服核实'
          }).then(() => {
            // on close
          });
        }
        this.dataInfo = resultData;
      }
    },
    // 标签
    getInputValueInfo(msg, index) {
      this.dataInfo.infos[index].signName = msg;
    },
    submitContent:debounce(async function() {
      let argArray = [];
      let submitFlag = false;
      this.dataInfo.infos.map((item) => {
        if (item.userCode === this.result && !this.isMultiple) {
          item.isChoose = true;
        }
        if (item.isChoose) {
          argArray.push(item);
          if (item.signName.length > 0) {
            submitFlag = true;
          } else {
            submitFlag = false;
            Toast('请选择标签');
          }
        }
      });
      if (argArray.length === 0) {
        submitFlag = false;
        Toast('请选择户号');
      }
      if (this.dataInfo.infos.some((item) => item.isChoose) && submitFlag) {
        let arg = {
          token: this.dataInfo.token,
          infos: argArray
        };
        const { status, resultData } = await bindUserById(arg);
        if (status === 'complete') {
          // 更新是否绑定状态
          await store.dispatch('user/getHomeInfo', '');
          if (this.homeInfo.isBind) {
            if (
              this.pathUrl &&
              this.pathUrl.url &&
              this.pathUrl.url.length > 0 &&
              this.pathUrl.url !== '/Bind'
            ) {
              this.$router.push({ path: this.pathUrl.url });
            } else {
              this.$router.push({ name: 'Home', path: '/' });
            }
          } else {
            this.$router.push({ name: 'Home', path: '/' });
          }
        }
      }
    }, 3000)
  }
};
</script>

<style lang="less" scoped>
.selectBindAccount-page {
  width: 100%;
  height: 100%;
  .acount-title {
    padding: 20px 16px;
    background-color: #ffffff;
    p {
      font-family: PingFangSC-Medium;
      font-size: 16px;
      color: #1c2734;
      line-height: 26px;
      font-weight: 700;
      margin: 0px;
    }
    span {
      display: block;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      line-height: 26px;
      line-height: 26px;
    }
  }
  .account-content {
    ul {
      margin-bottom: 80px;
      li {
        background-color: #ffffff;
        margin: 16px;
        border-radius: 8px;
      }
    }
    .van-cell-group {
      margin: 2px;
    }
    .account-submit {
      width: 90%;
      position: fixed;
      bottom: 0px;
      padding: 16px;
      background: #ffffff;
      .van-button {
        border-radius: 8px;
      }
    }
  }
}
</style>

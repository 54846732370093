<template>
  <van-field class="tags" :label-width="labelWidth">
    <template #label>
      <span class="tags-label">标签</span>
    </template>
    <template #input>
      <div class="tags-input">
        <div class="tags-input-list">
          <van-tag
            class="tags-input-span"
            plain
            v-for="(item, index) in listTag"
            :key="index"
            :class="index === tagIndex ? 'tags-input-pitch' : 'tags-input-span'"
            @click="clickTag(index)"
          >
            <span>{{ item }}</span>
          </van-tag>
        </div>
        <div class="tags-input-entry">
          <div v-if="tagState === 0">
            <van-tag class="tie-span" plain @click="tagOther">
              <van-icon class="tie-icon" name="plus" size="15px" color="rgba(0, 0, 0, 0.6)" />
            </van-tag>
          </div>
          <div v-if="tagState === 1">
            <van-field
              class="tie-input"
              v-model="entryValue"
              placeholder="最多5个文字"
              maxlength="5"
              @input="getValue"
            >
              <template #button>
                <van-button class="tie-button" size="mini" type="info" @click="tagConfirm" :disabled="disabledFlag">
                  确定
                </van-button>
              </template>
            </van-field>
          </div>
          <div v-if="tagState === 2">
            <div :class="editState ? 'tie-edit' : 'tie-edit-default'">
              <span class="tie-edit-label" @click="tagCheck">{{ entryValue }}</span>
              <span class="tie-edit-division">｜</span>
              <span class="tie-edit-button" @click="tagCompiler">编辑</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </van-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: '50px'
    },
    currentIndex: { // 选择绑定账号页面需要
      type: Number,
      default: 0
    }
  },
  watch: {
    value: {
      handler: function() {
        if (this.value) {
          this.tagValue = this.value;
          this.tagIndex = this.listTag.indexOf(this.tagValue);
          if (this.tagIndex >= 0) {
            this.tagState = 0;
            this.editState = false;
          } else {
            this.tagState = 2;
            this.entryValue = this.value;
            this.editState = true;
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      listTag: ['我家', '父母家', '朋友家'],
      tagValue: '',
      entryValue: '',
      tagIndex: '',
      tagState: 0,
      editState: true,
      disabledFlag: true
    };
  },
  methods: {
    //标签选择
    clickTag(index) {
      this.tagValue = this.listTag[index];
      this.tagIndex = index;
      this.editState = false;
      if (this.entryValue) {
        this.tagState = 2;
      } else {
        this.tagState = 0;
      }
      this.$emit('getTagValue', this.tagValue, this.currentIndex);
    },
    //标签+
    tagOther() {
      this.tagState = 1;
    },
    //标签选择
    tagCheck() {
      this.tagValue = '';
      this.tagIndex = '';
      this.editState = true;
    },
    //标签编辑
    tagCompiler() {
      this.tagState = 1;
    },
    //编辑确认
    tagConfirm() {
      if (this.entryValue) {
        this.tagState = 2;
        this.tagValue = '';
        this.tagIndex = '';
        this.editState = true;
        this.$emit('getTagValue', this.entryValue, this.currentIndex);
      }else{
        this.tagState = 0;
      }
    },
    getValue(event) {
      this.disabledFlag = false;
      if (event.length <= 0) {
        this.disabledFlag = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.tags {
  padding: 0;
  .tags-label {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
  }
  .tags-input {
    width: 100%;
    .tags-input-list {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      display: inline-block; // 改动
      .tags-input-span {
        padding: 6px 10px;
        margin-bottom: 10px;
        color: rgba(151, 151, 151, 0.45);
        border-radius: 8px;
        display: inline-block; // 改动
        margin-right: 10px; // 改动
        span {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .tags-input-pitch {
        padding: 6px 10px;
        margin-bottom: 10px;
        color: rgba(0, 125, 255, 0.15);
        background: rgba(0, 125, 255, 0.15);
        border-radius: 8px;
        span {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #0a79ec;
        }
      }
    }
    .tags-input-entry {
      display: flex;
      flex-direction: column;
      .tie-span {
        padding: 6px 16px;
        border-radius: 8px;
        color: rgba(151, 151, 151, 0.45);
      }
      .tie-input {
        padding: 0;
        .tie-button {
          padding: 6px 10px;
          background: #3b94f0;
          border-radius: 8px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #ffffff;
        }
        /deep/.van-field__control {
          line-height: 26px;
          background-color: #f6f6f6;
          border-radius: 8px;
          text-indent: 1em;
          font-size: 14px;
        }
        /deep/.van-field__button {
          position: absolute;
          right: 0px;
        }
      }
      .tie-edit-default {
        float: left;
        display: flex;
        align-items: center;
        border: 1px solid rgba(151, 151, 151, 0.45);
        border-radius: 8px;
        overflow: hidden;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        .tie-edit-label {
          color: #5e6268;
          padding: 0 10px;
        }
        .tie-edit-division {
          display: none;
        }
        .tie-edit-button {
          background: #5e6268;
          color: #ffffff;
          padding: 2px 10px;
        }
      }
      .tie-edit {
        float: left;
        display: flex;
        align-items: center;
        border-radius: 8px;
        background: rgba(0, 125, 255, 0.15);
        border: 1px solid rgba(0, 125, 255, 0.15);
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #0a79ec;
        .tie-edit-label {
          padding: 0 10px;
        }
        .tie-edit-division {
          font-size: 17px;
        }
        .tie-edit-button {
          padding: 2px 10px;
        }
      }
      ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        font-size: 14px;
      }
      ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        font-size: 14px;
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        font-size: 14px;
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        font-size: 14px;
      }
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: 14px;
      }
    }
  }
}
</style>
